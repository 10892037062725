.addressForm {
    display: flex;
    flex-direction: column;
}

.addressForm label {
    font-weight: 500;
    font-size: 12px;
    margin-top: 10px;
}

#line1,
#line2,
#city {
    max-width: 500px;
}

#zipCode {
    max-width: 80px;
}

#state {
    max-width: 100px;
}