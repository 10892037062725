.subtotal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 100px;
    padding: 20px;
    background-color: white;
    /* border: 1px solid #dddddd; */
    border-radius: 3px;
    margin: 10px auto 50px;
}

.subtotal__gift {
    display: flex;
    align-items: center;
}

.subtotal button {
    background: linear-gradient(90deg, #4158D0, #C850C0);
    border-radius: 4px;
    width: 100%;
    height: 30px;
    margin-top: 10px;
    color: white;
    border: none;
}

.subtotal button:hover {
    transform: scale(1.05);
}