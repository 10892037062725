.checkout {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: white;
    height: max-content;
    font-family: 'Arial';
}

.checkout__title {
    margin-right: 10px;
    padding: 10px;
    border-bottom: 1px solid lightgray;
}


.checkout__top {
    width: 100%;
    min-width: 50%;
}

.checkout__bottom {
    width: 100%;
    min-width: 50%;
    margin-top: 0px;
}

.checkout__warningText {
    margin-left: 20px;
    font-size: 25px;
    color: gray;
    text-align: center;
}