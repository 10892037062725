.collapsible__triggerButton {
    margin-top: 10px;
    background-color: white;
    color: #111;
    border-radius: 0;
    border: none;
    border-top: solid 1px gray;
    height: 48px;
    font-size: 18px;
    font-weight: 600;
}

.collapsible__trigger {
    display: flex;
    justify-content: space-between;
}

.collapsible__content {
    font-size: 14px;
    color: gray;
    margin-left: 8px;
}