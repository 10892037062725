.header {
    height: 50px;
    display: flex;
    align-items: center;
    /* background-color: #131921; */
    background-color: rgb(41, 41, 41);
    position: sticky;
    top: 0;
    z-index: 100;
    justify-content: space-between;
}

.header__brandName {
    margin: 10px;
    color: White;
    font-family: 'Pacifico';
    font-weight: 400;
    font-size: 16px;
}

/* .header__logo {
    width: 100px;
    object-fit: contain;
    margin: 0 20px;
    margin-top: 18px;
} */

/* .header__searchIcon {
    padding: 5px;
    height: 22px;
    background-color: #cd9042;
} */

/* .header__search {
    display: flex;
    flex: 1;
    align-items: center;
    border-radius: 24px;
} */

/* .header__searchInput {
    height: 12px;
    padding: 10px;
    border: none;
    width: 100%;
} */

.header__option {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-right: 15px;
    color: white;
}

.header__nav {
    display: flex;
    justify-content: space-evenly;
}

.header__optionLineTwo {
    font-size: 13px;
    font-weight: 400;
    margin-top: 5px;
    color: white;
}

.header__optionBasket {
    margin-top: 5px;
    display: flex;
    align-items: center;
    color: white;
    margin-left: 15px;
}

.header__basketCount {
    margin-left: 5px;
    margin-right: 20px;
}