.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
    background-color: rgb(240, 240, 240);
}

.home__row {
    display: flex;
    z-index: 1;
    margin-left: 5px;
    margin-right: 5px;
}

.home__image {
    width: 100%;
    z-index: -1;
    /* margin-bottom: -50px; */
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5));
}

.home__container {
    height: 700px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: grid;
    place-items: center;
}

.home__welcomeText {
    align-items: center;
    display: flex;
    flex-direction: column;

}

.home__welcomeText h1 {
    font-family: 'Pacifico';
    font-weight: 900;
    font-size: 120px;
    background-image: linear-gradient(120deg, #4158D0, #C850C0, #FFCC70);
    background-clip: text;
    color: transparent;
}

.home__welcomeText p {
    margin-top: 16px;
    font-family: 'Arial';
    font-size: 18px;
}