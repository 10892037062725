.productDetail {
    display: flex;
    flex-direction: column;
    background-color: rgb(240, 240, 240);
}


.productDetail__productCard {
    width: 70vw;
    min-width: 600px;
    background-color: white;
    margin: 0 auto;
}

.productDetail__info {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin: 30px 30px 40px;
}

.productDetail__title {
    font-size: 20px;
    font-weight: 600;
}

.productDetail__rating {
    display: flex;
    margin-top: 10px;
}

.productDetail__image {
    max-width: 500px;
    max-height: 400px;
    margin: 20px auto;
}

.productDetail__price {
    font-size: 20px;
}

.Collapsible {
    background-color: gray;
    height: 30px
}

hr {
    height: 1px;
    background-color: gray;
}


.productDetail__middle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}


.productDetail .productDetail__middle button {
    width: 180px;
    background: linear-gradient(90deg, #4158D0, #C850C0);
    border-radius: 4px;
    border: none;
    color: white;
}