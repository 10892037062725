.payment {
    background-color: white;
}

.payment__container h1 {
    text-align: center;
    padding: 10px;
    font-weight: 400;
    background-color: rgb(234, 237, 237);
    border-bottom: 1px solid lightgray;
}

.payment__container h1 a {
    text-decoration: none;
}

.payment__section {
    display: flex;
    padding: 20px;
    margin: 0 20px;
    border-bottom: 1px solid lightgray;
}

.payment__title {
    flex: 0.3;
}

.payment__address,
.payment__item,
.payment__detail {
    flex: 0.7;
}

.payment__detail form {
    max-width: 400px;
}

.payment__detail h3 {
    padding-bottom: 20px;
}

.payment__detail form div button {
    background: linear-gradient(90deg, #4158D0, #C850C0);
    border: 1px solid;
    margin-top: 10px;
    margin-bottom: 20px;
    color: white;
    width: 100%;
    height: 30px;
    border-radius: 4px;
    text-align: center;
}

.payment__priceContainer text {
    font-size: 18px;
}

.payment__price {
    margin-top: 30px;
}