.footer {
    text-align: center;
    background-color: rgb(41, 41, 41);
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.footer__text {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    font-size: 12px;
    color: rgb(131, 131, 131);
}