.checkoutProduct {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Arial';
}

.checkoutProduct__info {
    padding: 20px;
}

.checkoutProduct button {
    background: black;
    margin-top: 10px;
    color: lightgray;
    border-radius: 4px;
    font-size: 12px;
}

.checkoutProduct__image {
    object-fit: contain;
    width: 180px;
    height: 180px;
}

.checkoutProduct_rating {
    display: flex;
}

.checkoutProduct__title {
    font-size: 16px;
    font-weight: 600;
}