.product {
    display: flex;
    flex-direction: column;
    background-color: white;
    z-index: 1;
    align-items: center;
    justify-content: start;
    margin: 10px;
    padding: 20px;
    width: 100%;
    height: 420px;
    min-width: 200px;
    border-radius: 8px;
}

.product:hover {
    transform: scale(1.03);
}

.product img {
    position: relative;
    top: 10px;
    max-height: 180px;
    width: 100%;
    object-fit: contain;
    margin-bottom: 15px;
}

.product__info {
    margin-bottom: 15px;
    height: 120px;
}

.product button {
    width: 180px;
    background: black;
    border-radius: 5px;
    border: 1px solid;
    /* border-color: #a88734 #9c7131 #846a29; */
    color: lightgray;
    margin-top: 10px;
}

.product__rating {
    display: flex;
}

.product__title {
    flex: auto;
    max-height: 70px;
    font-size: 16px;
    overflow: hidden;
}

.product__price {
    margin-top: 5px;
    margin-bottom: 3px;
}