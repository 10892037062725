.register {
    height: 100vh;
    /* background-color: white; */
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
}


.register__brandName {
    margin-top: 30px;
    color: #111;
    font-family: 'Pacifico';
    font-weight: 400;
    font-size: 20px;
}

.register__container {
    margin-top: 50px;
    background-color: rgb(238, 238, 238);
    width: 320px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 20px;
    padding-bottom: 30px;
}

.register__container h1 {
    font-weight: 500;
    font-size: 26px;
    margin-bottom: 20px;
    font-family: 'Arial';
}

.register__container form h5 {
    margin-bottom: 5px;
    font-size: 12px;
    font-family: 'Arial';
}

.register__container form input {
    height: 30px;
    margin-bottom: 10px;
    background-color: white;
    width: 98%;
}

.register__container p {
    margin-top: 15px;
    font-size: 12px;
}

.register__container input {
    border: 1px solid lightgray;
    border-radius: 4px;
}

.register__registerButton {
    width: 100%;
    height: 30px;
    background: linear-gradient(90deg, #4158D0, #C850C0);
    color: white;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
}